import React from 'react'
import '../../App.css'
import HeroSection from '../HeroSection'
import './/Pages.css'

function Home() {
    return (
        <>
            <HeroSection/>
        </>
    )
}

export default Home
