import React from 'react'
import '../../App.css'
import './Pages.css'


export default function IrrelevantExperience() {
    return (
        <div className='page-container'>
            <h1>IRRELEVANT EXPERIENCE</h1>
            <div className='column-layout'>
            <div className="left-align">
                <h1>Sales Associate - Shoreline Sightseeing</h1>
                <div className="dates">
                    <h1>April 2021 - August 2022</h1>
                </div>
                <ul>
                    <li><p>Facilitate ticket sales for a boat tour company in downtown Chicago</p></li>
                    <li><p>Interact with diverse groups of people</p></li>
                </ul>
                <br/>
                <h1>Chicken Sitter - My Neighbor</h1>
                <div className="dates">
                    <h1>June 2021</h1>
                </div>
                <ul>
                    <li><p>Watch my neighbor's chickens while she was out of town</p></li>
                    <li><p>Learned where eggs come from</p></li>
                </ul>
                <br/>
                <h1>Studied Abroad - London</h1>
                <div className="dates">
                    <h1>May 2023</h1>
                </div>
                <ul>
                    <li><p>Ate beans on toast and saw Big Ben</p></li>
                    <li><p>Worked on my British accent</p></li>
                    <li><p>Also studied abroad in Ibiza</p></li>
                </ul>
                <br/>
                <h1>Brother - My Siblings</h1>
                <div className="dates">
                    <h1>April 7, 2004 - Present</h1>
                </div>
                <ul>
                    <li><p>Endured life with my 2 older brothers and younger sister for 20 years and counting</p></li>
                    <li><p>Settled numerous fights increasing peace in our house by over 200% for 10+ years</p></li>
                    <li><p>Brought in hundreds of pounds of groceries while my siblings were too busy "using the bathroom"</p></li>
                </ul>
                <br/>
                <h1>Town Hall 12 - Clash of Clans</h1>
                <div className="dates">
                    <h1>August 2021 - Present</h1>
                </div>
                <ul>
                    <li><p>Town Hall 12, original and innovative base design</p></li>
                    <li><p>3784 Trophies, Champion League II</p></li>
                    <li><p>39k+ troops donated</p></li>
                </ul>
                <br/>
                <h1>SKILLS</h1>
                <ul>
                    <li><p>Juggling</p></li>
                    <li><p>Can solve a Rubik's cube, PB: 36 seconds</p></li>
                    <li><p>French(75 day DuoLingo Streak)</p></li>
                    <li><p>Memorized 65+ digits of Pi</p></li>
                    <li><p>Had the most killer mullet this side of the Mississippi</p></li>
                </ul>
            </div>
            <div className='right-align'>
                  <img src='../../images/img-5284.jpg' alt="Me"/>
                  <p className='caption'>"Studying" abroad in Ibiza at Calvin Harris</p>
                  <img src='../../images/img-3349.png' alt="Me"/>
                  <p className='caption'>The mullet in question</p>
              </div>
            </div>
        </div>
    )
}